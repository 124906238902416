<template>
  <div class="card border-0 h-100 border-bottom">
    <div
      class="card-body px-0 pb-3 flex-column row justify-content-between"
      style="cursor: pointer"
      @click="item.path ? $router.push(item.path) : $router.push('/')"
    >
      <div>
        <div class="card-img" v-if="showImage && imgPos === 'header'">
          <img
            :src="image"
            alt="news"
            class="img-fluid mb-3"
            style="max-height: 200px; object-fit: cover; object-position: center"
          />
        </div>
        <h6 class="card-title fs-5 mb-3 text-primary fsz-5-limit-2">{{ item.title }}</h6>
        <div class="row mb-3 mb-lg-5">
          <p class="card-text fs-7" :class="showImage && imgPos === 'body' ? 'col-6' : 'col-12'">
            {{ item.brief || "-" }}
          </p>
          <div class="col-6" style="min-height: 120px" v-if="showImage && imgPos === 'body'">
            <div class="w-100">
              <div class="position-absolute top-0 end-0">
                <img
                  :src="image"
                  alt="news"
                  class="img-fluid obj-fit-contain"
                  style="height: 120px"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <p class="card-text fw-light fs-8 text-gray-500">
        {{ $utilsFilters.hpiDateFormat(item.createTime) || "-" }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "newsCard",
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
    showImage: {
      type: Boolean,
      default: false,
    },
    imgPos: {
      type: String,
      validator: (val) => ["header", "body"].indexOf(val) !== -1,
      default: "body",
    },
  },
  computed: {
    image() {
      const { imagePath } = this.item;
      return imagePath
        ? this.$imageFilters(imagePath)
        : "https://via.placeholder.com/300x140.png?text=Image";
    },
  },
};
</script>
