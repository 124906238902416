<template>
  <!-- banner -->
  <AppBanner :url="require('@/assets/image/cover/news-info.png')" :title="title" />
  <!-- nav -->
  <AppBreadcrumb :items="breadcrumb" :border="false" />
  <div class="container">
    <div class="py-3">
      <ul class="row gx-8">
        <li class="mb-3 mb-lg-5 col-6" v-for="article in articles" :key="article.articleUuid">
          <NewsCard :item="cardItem(article)" :showImage="true" imgPos="body" />
        </li>
      </ul>
      <Pagination :pages="pages" @changePage="changePage" />
    </div>
  </div>
</template>

<script>
import { apiQueryUserArticle } from "@/api/article";
import { mapGetters } from "vuex";
import { NEWS_INFO } from "@/constants/article";
import Pagination from "@/components/Pagination.vue";
import NewsCard from "@/components/NewsCard.vue";

export default {
  components: {
    Pagination,
    NewsCard,
  },
  data() {
    return {
      activeId: "NEWS",
      navs: [
        { id: 1, name: "新聞消息", path: "/news/1" },
        { id: 2, name: "公告", path: "/news/2" },
        { id: 3, name: "活動", path: "/news/3" },
      ],
      articles: [],
      pages: {
        totalPages: 1,
        currentPage: 0,
      },
    };
  },
  computed: {
    ...mapGetters("category", ["getCategory"]),
    title() {
      return this.navs.find((nav) => nav.id === this.activeId)?.name || "";
    },
    cardItem() {
      return (item) => ({ ...item, path: `/news/${item.articleUuid}/${item.articleUuid}` });
    },
    navItem() {
      const result = this.getCategory(NEWS_INFO.key);
      return result.map((r) => ({ ...r, path: `/news/category=${r.id}` }));
    },
    breadcrumb() {
      return [
        {
          title: "最新消息",
          path: "/news",
        },
        {
          title: this.title,
        },
      ];
    },
  },

  methods: {
    async queryArticleList({ page = 1, categoryId }) {
      this.$vLoading(true);
      try {
        const res = await apiQueryUserArticle({ page, categoryId, type: NEWS_INFO.key });
        const { code, data, message } = res.data;
        if (code === 200) {
          const { content, pageInfo } = data;
          const { totalPages, currentPage } = pageInfo;
          this.articles = content;
          this.pages = {
            totalPages,
            currentPage,
          };
        } else {
          this.$vHttpsNotice({ statusCode: code, title: message });
        }
      } catch (error) {
        this.$vErrorNotice();
      } finally {
        this.$vLoading(false);
      }
    },
    changePage(page) {
      this.queryArticleList({ page, categoryId: this.activeId });
    },
  },
  watch: {
    activeId(val) {
      if (val) {
        this.queryArticleList({ categoryId: val });
      }
    },
  },
  async created() {
    const { categoryId } = this.$route.params;
    this.activeId = Number(categoryId);
    await this.queryArticleList({ categoryId: this.activeId });
    if (this.articles.length === 0) {
      this.$router.push({ name: "news" });
    }
  },
};
</script>
